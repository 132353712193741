import { userAxios } from '../index'

const facilityList = async (perPage) => {
    try {
        return await userAxios.get(`clinics/all/${perPage}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const facilityCreate = async (data) => {
    try {
        return await userAxios.post('clinics', data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const facilityUpdate = async (id, data) => {
    try {
        return await userAxios.put(`clinics/${id}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const facilityDetail = async (id) => {
    try {
        return await userAxios.get(`clinics/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const facilityPagination = async (perPage, page) => {
    try {
        return await userAxios.get(`clinics/all/${perPage}?page=${page}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const facilityDelete = async (id) => {
    try {
        return await userAxios.delete(`clinics/${id}`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const facilityFilter = async (perPage, data) => {
    try {
        return await userAxios.post(`clinics/search/${perPage}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const facilityFilterPagination = async (perPage, page, data) => {
    try {
        return await userAxios.post(`clinics/search/${perPage}?page=${page}`, data).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

const FacilityTypes = async () => {
    try {
        return await userAxios.get(`clinics/types`).then(res => res.data)
    } catch (error) {
        console.log(error);
    }
}

export default {
    facilityList,
    facilityCreate,
    facilityUpdate,
    facilityDetail,
    facilityDelete,
    facilityPagination,
    facilityFilter,
    facilityFilterPagination,
    FacilityTypes
}
