<template>
    <div>
        <!-- <ConsultAdd
      :is-add-new-consult-sidebar-active.sync="isAddNewConsultSidebarActive"
      @createConsult="createConsult"
    /> -->
        <b-sidebar id="add-new-patient-sidebar" :visible="verifyBool" bg-variant="white" sidebar-class="sidebar-lg" shadow
            backdrop no-header right @change="(val) => changeStateSidebar(val)">
            <template #default="{ hide }">
                <!-- Header -->
                <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                    <h5 class="mb-0">
                        Verify cupon
                    </h5>

                    <i class="fas fa-times ml-1 cursor-pointer" size="16" @click="hide"></i>
                </div>

                <div class="p-2">
                    <checkCupon :cupon.sync="cupon" @cancelCupon="cancelCupon" @cuponVerified="cuponVerified" />

                    <div v-if="info.active" class="campaign-info">
                        <h4 class="info-title">Campaign: {{ info.campaign }}</h4>
                        <div class="info-details">
                            <p>Coupons per patient: {{ info.cupons_per_patient }}</p>
                            <p>Quantity: {{ info.quantity }}</p>
                            <p>Value: {{ info.value }}</p>
                            <p>Type: {{ info.type }}</p>
                            <p>Campaign ID: {{ info.id_campaign }}</p>
                            <p>End Date: {{ info.end_date }}</p>
                            <p>IP Client: {{ info.ip_client }}</p>
                            <p>Phone Number: {{ info.phone_number }}</p>
                            <p>Status: {{ checkStatus(info.status) }}</p>
                        </div>
                    </div>
                </div>

                <div class="d-flex mt-2 p-2">
                    <b-button type="button" variant="outline-danger" @click="hide">
                        Cancel
                    </b-button>
                </div>
            </template>
        </b-sidebar>
        <ToastNotification ref="toast" />
    </div>
</template>

<script>
import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import SpinnerLoading from "@/components/SpinnerLoading";
import flatPickr from "vue-flatpickr-component";

import { ref } from "@vue/composition-api";

import ToastNotification from "@/components/ToastNotification";
import { getStatus } from "@/mixins/cupon";
import checkCupon from "@/components/checkCupon";

/* import ConsultAdd from "@/views/admin/type-consult/consult/ConsultAdd"; */
export default {
    components: {
        BFormTextarea,
        BSidebar,
        BForm,
        BFormGroup,
        BFormInput,
        BFormInvalidFeedback,
        ToastNotification,
        BButton,
        SpinnerLoading,
        flatPickr,
        BInputGroup,
        BInputGroupAppend,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
        checkCupon,
    },
    model: {
        prop: "verifyBool",
        event: "update:verifyBool",
    },
    props: {
        verifyBool: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, { emit }) {
        const cupon = ref('')
        const info = ref({
            campaign: '',
            cupons_per_patient: 0,
            value: 0,
            quantity: 0,
            type: '',
            id_campaign: 0,
            end_date: '',
            ip_client: '',
            phone_number: '',
            status: 0,
            active: false
        })

        const cancelCupon = () => {
            cupon.value = ''
        }

        const cuponVerified = (newCupon) => {
            info.value.campaign = newCupon.campaign.name
            info.value.cupons_per_patient = newCupon.campaign.cupons_per_patient
            info.value.quantity = newCupon.campaign.quantity
            info.value.type = newCupon.campaign.type
            info.value.value = newCupon.campaign.value
            info.value.id_campaign = newCupon.campaigns_id
            info.value.end_date = newCupon.campaign.end_date
            info.value.ip_client = newCupon.ip_client
            info.value.phone_number = newCupon.phone_number
            info.value.status = newCupon.status
            info.value.active = true
            cupon.value = ''
        }

        const checkStatus = (status) => {
            return getStatus(status)
        }

        const changeStateSidebar = (val) => {
            emit('update:verifyBool', val)
            cupon.value = ''
        }

        return {
            changeStateSidebar,
            cupon,
            cancelCupon,
            cuponVerified,
            info,
            checkStatus
        };
    },
};
</script>

<style lang="scss" scoped>
.campaign-info {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    width: 100%;
}

.info-title {
    color: #3498db;
    font-size: 1.5em;
    margin-bottom: 10px;
}

.info-details p {
    margin: 5px 0;
}
</style>
